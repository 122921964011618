export const USER_EMAIL = 'user-email';
export const ACCESS_TOKEN = 'user-token';
export const USER = 'user';
export const USER_STATUS = 'user-status';
export const CLIENT_CODE = 'client-code';
export const CLIENT_ID = 'client-id';
export const QUOTE_CODE = 'quote-code';
export const PRODUCT_CODE = 'product-code';
export const PRODUCT_TERMS = 'product-terms';
export const USER_DATA = 'user-cached-data';
export const SOURCE_FUNDS_LIMIT = 1000000;
