<template>
  <v-app>
    <div>
      <keep-alive include="ELimuPlan, WholeLifePlan">
        <router-view :key="$route.fullPath" />
      </keep-alive>

      <v-snackbar v-model="snackbar.showing" :color="snackbar.color" bottom>
        <div class="tw-flex tw-justify-between tw-items-center">
          <span>{{ snackbar.text }}</span>
          <v-btn depressed text @click="snackbar.showing = false">close</v-btn>
        </div>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { USER } from '@/utils/const';

export default {
  name: 'App',
  computed: {
    ...mapState({
      snackbar: (state) => state.auth.snackbar,
    }),
  },
  beforeCreate() {
    if (this.$store.getters['auth/isAuthenticated'] && !this.$cookies.get(USER)) {
      this.$store.dispatch('auth/signOut').then(() => {
        this.$router.push({ name: 'SignIn' });
      });
    }
  },
  created() {
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
  },
  methods: {
    handleOffline() {
      this.$store.commit('SNACKBAR', {
        showing: true,
        text: "Seems you're offline! No Internet",
        color: 'error',
      });
    },
    handleOnline() {
      this.$store.commit('SNACKBAR', {
        showing: true,
        text: "You're back online!",
        color: 'success',
      });
      if (window.location.pathname === '/no-internet') {
        this.$router.go(-1);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  },
};
</script>
<style>
.v-date-picker-table {
    position: relative;
    padding: 0 12px;
    height: auto !important;
}
</style>
