<template>
  <div class="sidebar tw-h-full tw-bg-gray-800">
    <v-list dense>
      <!-- Logo -->
      <div class="tw-px-4 tw-py-3 md:tw-py-6 tw-flex tw-justify-center tw-items-center tw-mb-8">
        <router-link
          class="tw-flex tw-justify-center tw-flex-col tw-p-0"
          :to="{ name: 'Dashboard' }"
        >
          <img src="/logo.png" alt="APA Life Logo" class="tw-w-20 tw-mx-auto tw-opacity-100 hover:tw-opacity-150"/>
        </router-link>
      </div>
      <!-- Sidebar Items -->
      <template v-for="item in items" >
        <v-list-item
          :key="item.text"
          :to="item.link"
          class="sidebar-item tw-mt-10 md:tw-mt-5 tw-px-4 tw-rounded-md tw-transition-all tw-duration-200 tw-cursor-pointer hover:tw-bg-gray-50 "
          active-class="active-item"
        >
          <v-list-item-action>
            <v-icon color="#ffffff90" class="tw-text-lg tw-opacity-100 hover:tw-opacity-120">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="tw-text-gray-300 tw-font-medium tw-text-sm  md:tw-text-base tw-opacity-100">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  data: () => ({
    items: [
      { icon: 'mdi-apps', text: 'Dashboard', link: { name: 'Dashboard' } },
      { icon: 'mdi-store', text: 'Products', link: { name: 'Products' } },
      { icon: 'mdi-file-document', text: 'Proposals', link: { name: 'Proposals' } },
      { icon: 'mdi-shield-check', text: 'Policies', link: { name: 'Policies' } },
    ],
  }),
};
</script>

<style scoped>
/* Sidebar Styling */
.sidebar {
  width: 95%;
  padding-top: 10px;
  background-color: #1f2937;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  transition: background-color 0.3s, color 0.3s;
}

.active-item {
  background-color: #3b82f6;
  color: white;
  border-radius: 8px;
}
.sidebar-item:hover {
  background-color: #374151;
}

img {
  width: 60%;
  transition: opacity 0.5s;
}
</style>
