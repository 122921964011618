<template>
  <div id="back-nav" class="tw-w-full tw-mt-0 md:tw-mb-2 tw-flex tw-justify-end">
    <div class="tw-flex tw-w-20 tw-cursor-pointer tw-items-center tw-content-center" @click="navTo">
      <v-icon class="c-bg-blue tw-rounded-full tw-w-8 tw-h-8" color="#fff">mdi-arrow-left</v-icon>
      <div class="c-gray-text tw-ml-3">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackNav',
  props: {
    to: String,
    pageChange: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    navTo() {
      if (this.to === null) this.$router.back();
      if (this.pageChange) {
        this.$router.push({ name: this.to });
        return;
      }
      this.$root.$emit('nav-back-to', { currentTab: this.to });
    },
  },
};
</script>
