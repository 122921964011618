<template>
<default>
<back-nav to="SignIn"/>
<div>
  <v-container>
    <v-row
    no-gutters
  >
      <v-col
      lg="3"
      offset-lg="4"
      md="6"
      offset-md="3"
      sm="9"
      offset-sm="2"
      align-self="center">
  <v-card
  :loading="loading"
  class="tw-mx-2 tw-my-2" min-width="400"
  >
  <template v-slot:loader="{ isActive }">
    <v-progress-linear
  :active="isActive"
  height="4"
  color="deep-purple-accent-4"
  indeterminate
  >
  </v-progress-linear>
  </template>
    <div class="tw-w-full tw-flex tw-justify-center">
              <img
                src="/apalife-logo.svg"
                class="tw-w-24 md:tw-w-32 tw-mt-4 md:tw-mt-6"
                alt="logo"
              />
            </div>
    <v-card-title class="primary--text tw-font-bold tw-mt-2 tw-text-center">
     Reset Password
    </v-card-title>
    <div v-if="show">
    <v-card-subtitle class="gray--text">Your password reset code will be sent to your email address.</v-card-subtitle>
    <v-card-text>
      <div>
        <v-form  ref="form" @submit.prevent="submitForm" validate-on="submit" >
          <v-text-field
          id="agentCode"
          label="Agent Code"
          prepend-icon="mdi-account"
          v-model="agentCode"
          :rules="[required('Agent Code')]">
            Agent Code
          </v-text-field>
        </v-form>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn id="button" class="white--text tw-content-center"  color="success" @click="sendOtp(agentCode)" :disabled="!agentCode"> Send OTP</v-btn>
    </v-card-actions>
</div>
    <reset-otp v-else  @resend-otp="sendOtp" @reset-pwd="resetPassword" ></reset-otp>
  </v-card>
</v-col>
    </v-row>
  </v-container>
  <v-snackbar id="snackbar"  v-model="snackbar" :color="error ? 'error' : 'success'">
   {{ text }}
   <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
  </v-snackbar>
</div>
</default>
</template>

<script>
import axios from 'axios';
import Default from '../../layouts/Default.vue';
import BackNav from '../../components/BackNav.vue';
import ResetOtp from './ResetOtp.vue';
import validations from '../../utils/validations';

export default {
  name: 'ResetPassword',
  components: { Default, BackNav, ResetOtp },
  data() {
    return {
      snackbar: false,
      isValid: false,
      show: true,
      agentCode: '',
      error: '',
      text: '',
      loading: false,
      ...validations,
    };
  },
  methods: {
    sendOtp(agentNo) {
      this.agentCode = agentNo;
      this.loading = true;
      this.isValid = true;
      axios.post(`/lmsagent/login/InitiateResetAgentPassword?agentCode=${this.agentCode}`)
        .then((res) => {
          if (res.data.success === true) {
            this.text = 'OTP sent successfully';
            this.snackbar = true;
            this.show = false;
          } else {
            this.error = res.data.msg;
            this.text = this.error;
            this.snackbar = true;
          }
        });
      this.text = '';
      this.error = '';
      this.loading = false;
    },
    resetPassword(form) {
      this.loading = true;
      axios.post(`/lmsagent/login/resetAgentPassword?agentCode=${form.agentCode}&resetCode=${form.resetCode}&password=${form.confirmPassword}`)
        .then((res) => {
          if (res.data.success === true) {
            this.text = 'Password reset successfully';
            this.snackbar = true;
            setTimeout(() => this.$router.push({ name: 'SignIn' }), 3000);
          } else {
            this.error = res.data.msg;
            this.text = this.error;
            this.snackbar = true;
          }
        });
      this.text = '';
      this.error = '';
      this.loading = false;
    },
  },
};
</script>
