import cookie from 'vue-cookies';
import { USER_DATA } from '@/utils/const';

const data = {
  personalData: cookie.get(USER_DATA) || {
    name: '',
    email: '',
    dateOfBirth: '',
    term: null,
    freqOfPayment: '',
    benefits: [],
    amount: null,
  },
};
const mutations = {
  updatePersonalData(state, payload) {
    cookie.set(USER_DATA, payload);
    state.personalData = payload;
  },
};

export default {
  namespaced: true,
  state: data,
  mutations,
};
