<template>
  <v-app id="inspire">
    <v-navigation-drawer
      class="sidebar"
      v-model="drawer"
      :clipped="false"
      app
      v-if="isAuthenticated"
    >
      <Drawer />
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="false"
      app
      :flat="true"
      color="white"
    >
      <v-app-bar-nav-icon class="tw-block lg:tw-hidden" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <div v-if="isAuthenticated" class="tw-w-full tw-flex tw-justify-end tw-items-center">
        <v-btn  to="#" class="tw-mr-2" icon>
          <v-badge
            overlap
            color="error"
            content="0"
          >
            <v-icon class="c-gray-text">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
        <span class="tw-h-6 tw-border-r-2 tw-border-gray-600"></span>
        <Avatar />
      </div>
    </v-app-bar>

    <v-main>
      <div class="c-bg-gray tw-bg-bottom tw-bg-contain tw-min-h-screen" style="background-image: url('/images/smileys.png')">
        <v-container
          class="fill-heigh tw-min-h-full tw-pb-40 lg:tw-pb-16"
          fluid
        >

          <div class="tw-px-0 md:tw-px-10 tw-pb-10 md:tw-pb-48 tw-pt-4 md:tw-pt-10">
            <slot></slot>
          </div>

        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from '../components/layouts/Drawer';
import Avatar from '../components/layouts/Avatar';

export default {
  name: 'Default',
  components: { Avatar, Drawer },
  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
};
</script>

<style scoped>
.sidebar{
  background-color: #114cac;
  background-image: linear-gradient(#114cac, #012247);
}
</style>
