<template>
<div>
    <v-card-subtitle class="gray--text tw-my-2">Kindly check your email address for a reset code.</v-card-subtitle>
    <v-card-text>
      <div>
        <v-form v-model="isValid"  ref="form" @submit.prevent="submitForm" >
          <v-text-field
          id="agentCode"
          label="Agent Code"
          prepend-icon="mdi-account"
          v-model="form.agentCode"
          :rules="[required('Agent Code')]">
            Agent Code
          </v-text-field>
          <v-text-field
          class="tw-my-3"
          id="newPwd"
          label="New Password"
          :type="show ? 'text' : 'password'"
          prepend-icon="mdi-lock-reset"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="form.newPassword"
          :rules="[required('Password')]"
          @click:append="show = !show">
            New Password
          </v-text-field>
          <v-text-field
          class="tw-my-3"
          id="confirmPwd"
          label="Confirm Password"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          prepend-icon="mdi-lock-reset"
          v-model="form.confirmPassword"
          :rules="[required('Value'), confirmPassword('Value', form.newPassword)]"
          @click:append="show1 = !show1">
            Confirm Password
          </v-text-field>
          <v-text-field
          class="tw-my-3"
          id="resetCode"
          label="Reset Code (Sent to your email adress)"
          prepend-icon="mdi-numeric"
          v-model="form.resetCode"
          :rules="[required('Reset Code')]">
            Reset Code
          </v-text-field>
        </v-form>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn id="resetPwd"  class="white--text" color="primary" :disabled="!isValid" @click="resetPwd">Reset Password</v-btn>
      <v-btn id="resendOtp" class="white--text"  color="info" :disabled="!form.agentCode"   @click="resendOtp"> Resend OTP</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import validations from '../../utils/validations';

export default {
  data() {
    return {
      show: false,
      show1: false,
      isValid: false,
      value: true,
      form: {
        agentCode: '',
        newPassword: '',
        confirmPassword: '',
        resetCode: '',
      },
      loading: false,
      ...validations,
    };
  },
  methods: {
    resetPwd() {
      this.$emit('reset-pwd', this.form);
    },
    resendOtp() {
      this.loading = !this.loading;
      this.$emit('resend-otp', this.form.agentCode);
    },
  },
};
</script>
