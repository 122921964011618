import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#034EA2',
        secondary: '#F8AB10',
        accent: '#EFEFEF',
        accent2: '#F6F6F6',
        error: '#FF5252',
        info: '#616060',
        success: '#4CAF50',
        warning: '#FFC107',
        primary1: '#5d95f2',
        bluelight: '#E1F5FE',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
