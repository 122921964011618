import axios from 'axios';
import cookie from 'vue-cookies';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { USER, USER_STATUS } from '@/utils/const';

const data = {
  snackbar: {},
  loading: false,
  userInfo: cookie.get(USER) || null,
  userConfirmed: cookie.get(USER_STATUS) || false,
};
const getters = {
  isAuthenticated: (state) => state.userConfirmed,
  authenticatedUser: (state) => state.userInfo,
};
const mutations = {
  SIGN_IN(state, payload) {
    const agentData = payload.userData;
    cookie.set(USER, agentData);
    cookie.set(USER_STATUS, true);
    state.snackbar = payload.snackbar;
    state.userInfo = agentData;
    state.userConfirmed = true;
  },
  SNACKBAR(state, payload) {
    state.snackbar = payload.snackbar;
  },
  clearAuthentication(state) {
    cookie.remove(USER);
    cookie.remove(USER_STATUS);
    state.userInfo = null;
    state.userConfirmed = false;
  },
};
const actions = {
  login({ commit }, formData) {
    axios.post('/lmsagent/login/agentAuth', formData)
      .then((res) => {
        const userData = res.data;
        commit('SNACKBAR', {
          snackbar: {
            showing: true,
            text: `${res.data.agentLoginMsg}`,
            color: 'success',
          },
        });
        if (userData.agentLoginSuccess === 1) {
          commit('SIGN_IN', {
            snackbar: {
              showing: true,
              text: `${res.data.agentLoginMsg}`,
              color: 'success',
            },
            userData,
          });
          router.push({ name: 'Dashboard' });
        }
        if (userData.agentLoginSuccess === 0 || userData.agentLoginSuccess >= 2) {
          commit('SNACKBAR', {
            snackbar: {
              showing: true,
              text: `${res.data.agentLoginMsg}`,
              color: 'error',
            },
          });
        }
      })
      .catch((err) => {
        commit('SNACKBAR', {
          snackbar: {
            showing: true,
            text: `${err.data.agentLoginMsg}`,
            color: 'error',
          },
        });
      });
  },
  signOut({ commit }) {
    commit('clearAuthentication', { root: true });
    router.replace({ name: 'SignIn' });
  },

};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};
