import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import auth from './modules/auth';
import quote from './modules/quote';
import userPersonalData from './modules/userPersonalData';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    quote,
    userPersonalData,
  },
});
