<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-card class="tw-rounded-lg tw-shadow-lg tw-p-4">
      <!-- Title Section -->
      <v-card-title class="tw-text-lg tw-font-semibold tw-text-blue-600 tw-pb-4">
        {{ userData.agentName }} Profile
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="tw-flex tw-flex-wrap tw-gap-6 tw-mt-4 ">
        <!-- Change Password Section -->
        <div class="tw-w-full md:tw-w-3/3 pass tw-bg-gray-100 tw-p-4 tw-rounded-lg tw-shadow-sm">
          <h3 class="tw-text-base tw-font-semibold tw-text-gray-700">Change Password</h3>
          <p class="tw-text-sm tw-text-gray-600 tw-mb-4">You can change your password here for added security.</p>
          <v-text-field v-model="passwordData.oldPassword" label="Old Password" type="password" outlined dense
            prepend-icon="mdi-lock" class="tw-mb-3"></v-text-field>

          <v-text-field v-model="passwordData.newPassword" label="New Password" type="password" outlined dense
            prepend-icon="mdi-lock" class="tw-mb-3"></v-text-field>

          <v-btn color="primary" class="tw-w-full tw-font-semibold" @click="changePassword" disabled>Change Password</v-btn>
        </div>

        <!-- Profile Details Section -->
        <div class="tw-w-full md:tw-w-3/3 profile">
          <v-form ref="form">
            <v-text-field v-model="userData.agnCode" label="Your Agent Code" outlined dense readonly
              prepend-icon="mdi-account-badge"></v-text-field>

            <v-text-field v-model="userData.agenUserName" label="Registered Username" outlined dense readonly
              prepend-icon="mdi-account"></v-text-field>

            <v-text-field v-model="userData.agentName" label="Your Agent Name" outlined dense readonly
              prepend-icon="mdi-account-circle"></v-text-field>

            <v-text-field v-model="userData.agentNo" label="Your Agent Number" outlined dense readonly
              prepend-icon="mdi-card-account-details"></v-text-field>

            <v-text-field v-model="userData.agentLoginMsg" label="Login Status" outlined dense readonly
              prepend-icon="mdi-message"></v-text-field>
          </v-form>
        </div>
      </v-card-text>

      <!-- Actions Section -->
      <v-card-actions class="tw-justify-center">
        <v-btn class="tw-bg-gray-500" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'UpdateProfile',
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      userData: {
        agnCode: '',
        agenUserName: '',
        agentName: '',
        agentNo: '',
        agentLoginMsg: '',
      },
      passwordData: {
        oldPassword: '',
        newPassword: '',
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.userInfo,
    }),
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    changePassword() {
      this.passwordData = [''];
    },
  },
  watch: {
    user(newUser) {
      this.userData = { ...newUser };
    },
  },
  mounted() {
    this.userData = { ...this.user };
  },
};
</script>

<style scoped>
.v-card-title {
  padding-bottom: 16px;
}

.v-card-text {
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

@media (max-width: 768px) {
  .pass {
    width: 100%;
  }

  .profile {
    width: 100%;
  }
}
</style>

//api.js and updateprofile the new components tested 100%coverage
