import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import SignIn from '../views/auth/SignIn';
import ResetPassword from '../views/auth/ResetPassword';

Vue.use(VueMeta);
Vue.use(VueRouter);

const ifNotAuthenticated = (_to, _from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/dashboard');
};

const ifAuthenticated = (_to, _from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/');
};

const routes = [
  {
    path: '/',
    name: 'SignIn',
    beforeEnter: ifNotAuthenticated,
    component: SignIn,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    beforeEnter: ifNotAuthenticated,
    component: ResetPassword,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/Dashboard'),
    meta: { keepAlive: true },
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products'),
  },
  {
    path: '/products/rfq/elimu-plan',
    name: 'ElimuPlan',
    component: () => import('../views/rfq/ElimuPlan'),
    meta: { keepAlive: true },
  },
  {
    path: '/products/rfq/Imarika',
    name: 'Imarika',
    component: () => import('../views/rfq/ImarikaPlan'),
  },
  {
    path: '/products/rfq/pumzisha-plan',
    name: 'PumzishaPlan',
    component: () => import('../views/rfq/PumzishaPlan.vue'),
  },
  {
    path: '/products/rfq/ElimuPlus-plan',
    name: 'ElimuPlusPlan',
    component: () => import('../views/rfq/ElimuPlusPlan.vue'),
  },
  {
    path: '/products/rfq/akiba-halisi-plan',
    name: 'AkibaPlan',
    component: () => import('../views/rfq/AkibaHalisiPlan'),
  },
  {
    path: '/products/rfq/term-assurance-plan',
    name: 'TermAssurancePlan',
    component: () => import('../views/rfq/TermAssurancePlan'),
  },
  {
    path: '/products/rfq/whole-life-plan',
    name: 'WholeLifePlan',
    component: () => import('../views/rfq/WholeLifePlan'),
  },
  {
    path: '/products/rfq/wealth-builder-plan',
    name: 'WealthBuilderPlan',
    component: () => import('../views/rfq/WealthBuilderPlan'),
  },
  {
    path: '/products/rfq/keyman-plan',
    name: 'KeymanPlan',
    component: () => import('../views/rfq/KeymanPlan'),
  },
  {
    path: '/products/rfq/individual-pension-plan',
    name: 'IndividualPensionPlan',
    component: () => import('../views/rfq/IndividualPensionPlan.vue'),
  },
  {
    path: '/cover/agent-guide',
    name: 'AgentGuide',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/cover/Guide'),
  },
  {
    path: '/cover/buy-cover',
    name: 'BuyCover',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/cover/BuyCover'),
  },
  {
    path: '/cover/proposals',
    name: 'Proposals',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/cover/Proposals'),
  },
  {
    path: '/cover/policies',
    name: 'Policies',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/cover/Policies'),
  },
  {
    path: '/error',
    name: 'ServerError',
    component: () => import('../views/errors/Error50x'),
    meta: { noNavigation: true },
  },
  {
    path: '/no-internet',
    name: 'NoInternet',
    component: () => import('../views/errors/Connection'),
    meta: { noNavigation: true },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/errors/Error404'),
    meta: { noNavigation: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, _from, _savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
