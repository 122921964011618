import Vue from 'vue';
import VueIziToast from 'vue-izitoast';
import VueCookies from 'vue-cookies';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import '@/assets/css/color/default.css';
import '@/assets/css/nprogress.css';
import '@/assets/css/style.css';
import '@/assets/css/tailwind.css';
import 'izitoast/dist/css/iziToast.css';
import '@/plugins/vuetify-money';
import Initialize from '@/utils/initialize';
import helperMixins from '@/mixins/helperMixins';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueCookies);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.mixin(helperMixins);

Vue.use(VueIziToast, { position: 'topRight', timeout: 5000 });

Vue.$cookies.config('7d');

Vue.use(Toast);

if (process.env.VUE_APP_TAWK_PROPERTY_ID && process.env.VUE_APP_TAWK_WIDGET_ID) {
  Vue.use(TawkMessengerVue, {
    propertyId: process.env.VUE_APP_TAWK_PROPERTY_ID,
    widgetId: process.env.VUE_APP_TAWK_WIDGET_ID,
  });
}

Initialize.init(router, store);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
