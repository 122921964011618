/* eslint-disable radix */
import axios from 'axios';
import cookie from 'vue-cookies';
import {
  CLIENT_CODE, QUOTE_CODE, CLIENT_ID, PRODUCT_CODE, PRODUCT_TERMS,
} from '@/utils/const';

const data = {
  isSaved: false,
  savedQuoteCode: '',
  savedQuoteData: {},
  clientCode: parseInt(cookie.get(CLIENT_CODE)) || '',
  clientId: parseInt(cookie.get(CLIENT_ID)) || null,
  quoteCode: parseInt(cookie.get(QUOTE_CODE)) || '',
  quoteSourceOfFunds: '',
  productCode: parseInt(cookie.get(PRODUCT_CODE)) || '',
  productTerms: parseInt(cookie.get(PRODUCT_TERMS)) || '',
  lifeAssuredKYC: {},
  lifeAssuredKYCImages: {},
  policyOwnerKYC: {},
  beneficiaries: [],
  healthQuestionaire: {},
  goForMedicals: '',
};
const getters = {
  beneficiaries(state) {
    return state.beneficiaries;
  },
  policyOwner(state) {
    return state.policyOwnerKYC;
  },
  savedQuoteData(state) {
    return state.savedQuoteData;
  },
  getProductCode(state) {
    return state.productCode;
  },
};
const mutations = {
  setSaved(state, payload) {
    if (payload === false) {
      state.isSaved = false;
    } else if (payload.savePoint !== null) {
      state.isSaved = true;
      state.savedQuoteCode = payload.quoteCode;
      state.savedQuoteData = payload;
    } else {
      state.isSaved = false;
    }
    state.quoteCode = payload.quoteCode;
  },
  resetSaved(state, payload) {
    if (payload === false) {
      state.isSaved = false;
    }
  },
  setSavedQuoteCode(state, payload) {
    state.quoteCode = payload.quoteCode;
  },
  clientInfo(state, payload) {
    cookie.set(CLIENT_CODE, payload.client.clientCode);
    cookie.set(QUOTE_CODE, payload.quoteCode);
    cookie.set(PRODUCT_CODE, payload.prodCode);
    cookie.set(PRODUCT_TERMS, payload.prodCode);
    state.clientCode = payload.client.clientCode;
    state.quoteCode = payload.quoteCode;
    state.productCode = payload.prodCode;
    state.productTerms = payload.term;
  },
  lifeAssuredInfo(state, payload) {
    cookie.set(CLIENT_ID, payload.idNumber);
    state.clientId = payload.idNumber;
    state.lifeAssuredKYC = payload;
  },
  lifeAssuredImages(state, payload) {
    state.lifeAssuredKYCImages = payload;
  },
  policyOwnerInfo(state, payload) {
    state.policyOwnerKYC = payload;
  },
  beneficiariesInfo(state, payload) {
    const beneficiary = {
      quoteBenCode: 0,
      quoteBenQuote: state.quoteCode,
      quoBenName: `${payload.surname} ${payload.lastname}`,
      quoBenTel: payload.mobileNumber ? payload.mobileNumber : 'string',
      quoBenIdNo: payload.idNumber ? payload.idNumber : 0,
      quoBenDateOfBirth: payload.dateOfBirth,
      quoBenPerc: payload.percentage,
      quoBenRelType: payload.relationship,
      quoBenGuardianName: payload.guardian.fullname ? payload.guardian.fullname : 'string',
      quoBenGuardianTel: payload.guardian.mobileNumber ? payload.guardian.mobileNumber : 'string',
      quoBenGuardianIdNo: payload.guardian.idNumber ? payload.guardian.idNumber : 'string',
      quoBenGuardianRelType: payload.guardian.policyHolder ? payload.guardian.policyHolder : 'string',
    };
    state.beneficiaries.push(beneficiary);
  },
  updateBeneficiaries(state, payload) {
    state.beneficiaries = payload;
  },
  healthQuestionaireInfo(state, payload) {
    state.healthQuestionaire = payload;
  },
  lienOption(state, payload) {
    state.goForMedicals = payload;
  },
  sourceOfFundsInfo(state, payload) {
    state.quoteSourceOfFunds = payload;
  },
};
const actions = {
  saveBeneficiaries({ commit }, formData) {
    axios.post('/lmsagent/quotations/saveQuoteBeneficiaries', formData)
      .then((res) => {
        if (res.data.status === 200) {
          commit('SNACKBAR', {
            snackbar: {
              showing: true,
              text: 'Beneficiary details saved',
              color: 'success',
            },
          });
        }
      });
  },
};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};
