const helperMixins = {
  data() {
    return {
      phoneNumberBindProps: {
        mode: 'international',
        defaultCountry: 'KE',
      },
    };
  },
  methods: {
    is_null: (value) => value == null,

    is_object: (value) => Object.keys(value).length > 0,

    is_bool: (value) => value === true || value === false,

    scanObject(nestedObj, pathArr, defaultValue = null) {
      if (!this.isArray(pathArr)) {
        // eslint-disable-next-line no-param-reassign
        pathArr = pathArr.split('.');
      }

      const retrievedValue = pathArr.reduce(
        (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
        nestedObj,
      );
      return !this.is_null(retrievedValue) ? retrievedValue : defaultValue;
    },

    isArray(value) {
      return Array.isArray(value);
    },
  },
};

export default helperMixins;
