<template>
    <div class="tw-mb-1 c-blue-text">
      <v-menu v-model="menu" :close-on-content-click="false" offset-x offset-y>
        <template v-slot:activator="{ on }">
          <div class="tw-flex tw-px-2 tw-items-center tw-cursor-pointer" v-on="on">
            <div class="tw-hidden md:tw-flex tw-items-center tw-space-x-1 tw-text-gray-900 tw-font-sans tw-text-sm tw-mt-2">
              <span class="tw-text-base">{{ user }}</span>
              <v-icon color="blue">mdi-chevron-down</v-icon>
            </div>

            <avatar-icon :username="user" :size="32" color="white" backgroundColor="#114cac"
              class="tw-shadow-lg tw-rounded-full" />

            <div class="tw-absolute tw-block md:tw-hidden md:tw-mt-6 tw-ml-7 mt-1 ">
              <v-icon color="blue">mdi-chevron-down</v-icon>
            </div>
          </div>
        </template>

        <v-card class="tw-w-48 border tw-border-blue-500 tw-rounded-md tw-shadow-md hover:tw-bg-gray-900">
          <v-list dense>
            <v-list-item>
              <v-list-item-action>
                <router-link :to="{ name: 'Dashboard' }" class="tw-flex tw-items-center tw-space-x-2 tw-text-gray-800">
                  <v-icon color="primary">mdi-apps</v-icon>
                  <span>Dashboard</span>
                </router-link>
              </v-list-item-action>
            </v-list-item>

            <!-- Trigger the Update Profile dialog -->
            <v-list-item @click="openUpdateProfile">
              <v-list-item-action>
                <div class="tw-flex tw-items-center tw-space-x-2 tw-text-blue-800">
                  <v-icon color="primary">mdi-account-edit</v-icon>
                  <span>Update Profile</span>
                </div>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <a @click="onLogout" class="tw-flex tw-items-center tw-space-x-2 tw-text-gray-800 tw-cursor-pointer">
                  <v-icon color="primary">mdi-logout</v-icon>
                  <span>Logout</span>
                </a>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <!-- Update Profile Dialog Component -->
      <update-profile :dialog="updateProfileDialog" @close="updateProfileDialog = false" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AvatarIcon from 'vue-avatar';
import UpdateProfile from '../UpdateProfile/Updateprofile.vue';

export default {
  name: 'Avatar',
  data: () => ({
    menu: null,
    updateProfileDialog: false,
  }),
  components: {
    AvatarIcon,
    UpdateProfile,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.userInfo.agentName,
    }),
  },
  methods: {
    openUpdateProfile() {
      this.updateProfileDialog = true;
    },
    async onLogout() {
      await this.$store.dispatch('auth/signOut').then(() => {
        this.$router.push({ name: 'SignIn' });
      });
    },
  },
};
</script>
