<template>
  <div class="tw-w-full tw-flex flex-wrap tw-justify-center">
    <div class="tw-w-10/12 xl:tw-w-10/12 tw-text-center tw-flex flex-wrap tw-justify-center">
      <p class="tw-font-bold c-blue-text">Click here to get a quote within minutes</p>
      <div class="tw-w-full md:tw-w-8/12">
        <v-btn id="request-for-quote" :to="{name: 'Products'}" block large color="secondary" class="my-3 white--text tw-w-full animate-bounce" :disabled="!!query">Request for Quotation</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetQuote',
  props: ['query'],
};
</script>

<style lang="scss" scoped>
  .c-white-text{
    line-height: 55px;
    @media only screen and (min-width: 1367px) {
      font-size: 70px;
      line-height: 75px;
    }
  }
  .animate-bounce {
    animation: bounce 1s infinite
  };

  @keyframes bounce {
    0%, 100% {
      transform: translateY(-25%);
      animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
    }
  }
</style>
